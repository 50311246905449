import { css, useTheme } from '@emotion/react'
import styled from '@emotion/styled'
import { Helmet } from 'react-helmet-async'
import { Breadcrumbs } from '../components/breadcrumbs'
import { OuterV1, Inner, Section } from '../components/layout'
import { Locale } from '../components/locale'
import { Header } from '../components/header'
import { SC } from '../types'
import { Summary, useMicroCms } from '../hooks/use-micro-cms'
import { useAppSelector } from '../hooks/use-app-selector'
import { selectCategories } from '../store/blogs'
import { logger } from '../helpers'
import { useLoaderData } from 'react-router'
import { darkBgClassName } from '../constants'
import { useLocale } from '../hooks/use-locale'
import { useTitle } from '../hooks/use-title'
import { HeadingV1 } from '../components/heading'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { FeaturedNews } from '../components/news-section'
import { DetailLink } from '../components/detail-link'
import { Banner } from '../components/banner'
import { ContactSectionV1 } from '../components/contact-section'
import { motion } from 'framer-motion'
import { Slideshow, SlideshowItemOption, SlideshowStyleInterpolationFn } from '../components/slideshow'

const secondaryContentAnimationVariants = {
  hidden: {
    opacity: 0,
  },
  enter: {
    opacity: 1,
  },
}

const secondaryContentAnimationTransition = {
  delay: 1.2,
  opacity: {
    duration: 1,
  },
}

const SecondaryContent: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <motion.div
      initial="hidden"
      animate="enter"
      transition={secondaryContentAnimationTransition}
      variants={secondaryContentAnimationVariants}
    >
      {children}
    </motion.div>
  )
}

const HeroImage: React.FC<{ className?: string }> = ({ className }) => {
  const { v1: theme } = useTheme()
  const items = useMemo<SlideshowItemOption[]>(() => {
    const createPrimaryContent = () => (
      <>
        <Locale lang="ja">RECRUIT</Locale>
        <Locale lang="en">RECRUIT</Locale>
      </>
    )

    return [
      {
        sources: [
          [`(min-width: ${theme.breakpoint})`, '/images/recruithomepage-image-cover.jpg'],
          [null, '/images/recruithomepage-image-cover.jpg'],
        ],
        primaryContent: createPrimaryContent(),
        secondaryContent: (
          <SecondaryContent>
            <Locale lang="ja">
              どんな時でも、
              <br />
              自然が私を夢中にさせてくれたから。
            </Locale>
            <Locale lang="en">
              At any time,
              <br />
              Because nature has kept me occupied.
            </Locale>
          </SecondaryContent>
        ),
      },
    ]
  }, [theme.breakpoint])

  const handleStyle = useCallback<SlideshowStyleInterpolationFn>(
    ({ active }) => {
      return css`
        transform-origin: center;
        transform: ${active ? 'scale(1.0)' : 'scale(1.32)'};
        transition: transform linear 9000ms;

        @media (min-width: ${theme.breakpoint}) {
          transform-origin: center 60%;
          transform: ${active ? 'scale(1.0)' : 'scale(1.1)'};
        }
      `
    },
    [theme],
  )

  return (
    <div
      css={css`
        ${theme.colorMode.dark};
        height: 100vh;
        position: relative;
      `}
      className={className}
    >
      <Slideshow items={items} showIndicator={false} style={handleStyle} />
    </div>
  )
}

const useContetBaseCss = () => {
  const { v1: theme } = useTheme()

  return useMemo(() => {
    return css`
      margin-left: auto;
      margin-right: auto;
      @media (min-width: ${theme.breakpoint}) {
        max-width: 768px;
      }
    `
  }, [theme])
}

const RecruitContent = styled.div`
  ${(props) => props.theme.v1.text.base};
  ${(props) => props.theme.v1.fontWeight.medium};
  ${(props) => props.theme.v1.padding.x};
  padding-top: 0;
  padding-bottom: 0;

  @media (min-width: ${(props) => props.theme.v1.breakpoint}) {
    padding-left: 1.5rem;
  }
`
const RecruitContentBig = styled.div`
  ${(props) => props.theme.v1.text.lg};
  ${(props) => props.theme.v1.fontWeight.bold};
  ${(props) => props.theme.v1.padding.x};

  @media (min-width: ${(props) => props.theme.v1.breakpoint}) {
    padding-left: 1.5rem;
  }
`

const Block: SC<HTMLDivElement> = styled.div`
  & + ${() => Block} {
    margin-top: 4.6875rem;
  }

  @media (min-width: ${(props) => props.theme.v1.breakpoint}) {
    overflow-x: hidden;
  }
`

const BlockHeading = styled.div`
  ${(props) => props.theme.v1.text.base};
  ${(props) => props.theme.v1.fontWeight.medium};
  color: ${(props) => props.theme.v1.baseColor.black};
  margin-top: 0.75rem;
  text-align: center;
  ${(props) => props.theme.v1.padding.x};
  padding-top: 0;
  padding-bottom: 0;

  @media (min-width: ${(props) => props.theme.v1.breakpoint}) {
    padding: 0;
  }
`

const BlockTitleBase = styled.div`
  ${(props) => props.theme.v1.text.lg};
  ${(props) => props.theme.v1.fontWeight.bold};
  margin-bottom: 1rem;
  text-align: center;
`

const BlockTitleEm = styled(BlockTitleBase)`
  position: relative;
  padding-left: 1rem;

  ::before {
    content: '';
    display: block;
    position: absolute;
    top: 0.15rem;
    bottom: 0.15rem;
    left: 0;
    width: 2px;
    border-radius: 1px;
    background-color: ${(props) => props.theme.v1.baseColor.blue};
  }
`

const BlockTitle: React.FC<{
  border?: boolean
  className?: string
  children: React.ReactNode
}> = ({ border, className, children }) => {
  const { v1: theme } = useTheme()
  const BlockTitleComponent = border ? BlockTitleEm : BlockTitleBase

  return (
    <div
      css={css`
        ${theme.padding.x};
        padding-top: 0;
        padding-bottom: 0;

        @media (min-width: ${theme.breakpoint}) {
          padding: 0;
        }
      `}
      className={className}
    >
      <BlockTitleComponent>{children}</BlockTitleComponent>
    </div>
  )
}

const BlockContent = styled.div`
  ${(props) => props.theme.v1.text.base};
  ${(props) => props.theme.v1.fontWeight.medium};
  ${(props) => props.theme.v1.padding.x};
  padding-top: 0;
  padding-bottom: 0;

  @media (min-width: ${(props) => props.theme.v1.breakpoint}) {
    padding: 0;
  }
`

export const RecruitsPage: React.FC<{ className?: string; bannerClassName?: string }> = ({
  className,
  bannerClassName,
}) => {
  const { v1: theme } = useTheme()
  const microCms = useMicroCms()
  const categories = useAppSelector(selectCategories)
  const LoaderData = useLoaderData()
  const locale = useLocale()
  const contentBaseCss = useContetBaseCss()

  type FeaturedNewsState = {
    news: Summary[]
    think: Summary[]
  }

  const [featuredNews, setFeaturedNews] = useState<FeaturedNewsState>({
    news: [], // Initial empty array or placeholder data
    think: [], // Initial empty array or placeholder data
  })

  useEffect(() => {
    if (categories.length === 0) {
      return
    }
    if (LoaderData) {
      const elementData = document.getElementById(`${LoaderData}`)
      if (elementData) {
        const elementDataTop = window.scrollY + elementData.getBoundingClientRect().top
        window.scroll(0, elementDataTop)
      }
    }
    const newsCategoryId = categories.find((cat) => cat.name === 'JOIN US')?.id
    const thinkCategoryId = categories.find((cat) => cat.name === 'THINK')?.id
    if (newsCategoryId == null || thinkCategoryId == null) {
      return
    }
    ;(async () => {
      const newsData = await microCms.getBlogSummaries({ categoryId: newsCategoryId, locale })
      const thinkData = await microCms.getBlogSummaries({ categoryId: thinkCategoryId, locale })
      setFeaturedNews({
        news: newsData.contents,
        think: thinkData.contents,
      })
    })().catch((error) => logger.error(error))
  }, [categories, microCms, locale, LoaderData])

  const currentTitle = useTitle({
    ja: 'Recruits',
    en: 'Recruits',
  })

  return (
    <>
      <Helmet>
        <title>{currentTitle}</title>
      </Helmet>
      <OuterV1 headerSpace={false}>
        <Header darkBgSelector={`.${darkBgClassName}`} hasDarkBgByDefault={true} />
        <HeroImage className={darkBgClassName} />
        <div
          css={css`
            ${contentBaseCss};
          `}
        >
          <div
            css={css`
              padding-top: 50px;
            `}
          >
            <HeadingV1
              css={css`
                padding-left: 1.5rem;
              `}
            >
              Join the Philosophy
            </HeadingV1>
          </div>
          <RecruitContent>
            <Locale lang="ja">
              <p>
                2019年にイノカが産声を上げてから、まもなく5年が経ちます。その間、独自の「環境移送技術&reg;」を活かした世界初の真冬のサンゴ産卵の成功や海洋治験事業、教育・イベントを通じた世間の意識改革に取り組んできました。
              </p>
              <p>
                周知の事実ですが、環境問題という途方もない旅路では、小さくも確かな一歩をコツコツと積み重ねていくことが必要です。時には「地球の未来」というスケールの大きさに対し、「本当に貢献できているのか」と懐疑的になったり、責任感に押し潰されそうになったこともありました。
              </p>
              <p>
                それに、同課題は「正解が曖昧」という難しさもあります。多様な価値観、方法論、ゴール設定
                etc…。多くの関係者に出会う中で、信じるものが分からなくなる怖さも経験しました。
              </p>
              <p>しかし、それでも続けてこられたのは、</p>
            </Locale>
            <Locale lang="en">
              <p>
                It will soon be five years since innoqua was started in 2019. Since that time, we have successfully
                conducted the world's first mid-winter coral spawning using our proprietary "Environmental Transfer
                Technology&reg;," marine clinical trial projects, and raised public awareness through education and
                events.
              </p>
              <p>
                As is well known, the tremendous journey of environmental issues requires a steady accumulation of small
                but sure steps. At times, I have been skeptical about "the future of the Earth," wondering if I am
                really making a contribution, and at other times I have been almost crushed by the sense of
                responsibility.
              </p>
              <p>
                In addition, this issue is difficult because the correct answer is never set in stone. Environmental
                issues have diverse values, methodologies, and goals, so the correct answer is not always certain. Many
                people that I met have values different to mine, which causes me to be unsure what to believe in,
                causing me to doubt if I should continue with my activities.{' '}
              </p>
              <p>However, I have persevered,</p>
            </Locale>
          </RecruitContent>
          <RecruitContentBig
            css={css`
              padding-top: 20px;
              padding-bottom: 20px;
            `}
          >
            <Locale lang="ja">
              <p>どんな時でも、</p>
              <p>自然が私を夢中にさせてくれた。</p>
              <p>そんな自然が消える世界を、</p>
              <p>受け入れたくない。</p>
            </Locale>
            <Locale lang="en">
              <p>At any given moment,</p>
              <p>Nature has kept me engrossed.</p>
              <p>I don't want to accept a world in which nature disappears.</p>
            </Locale>
          </RecruitContentBig>
          <RecruitContent>
            <Locale lang="ja">
              <p>
                という、疑う余地のない自分自身の切実な想いに向き合ってきたからです。言い換えれば「生き物が好きな自分の気持ちにまっすぐに頑張ることが、結果的に社会や地球のためになる」というバランス感覚を持つこととも言えます。
              </p>
              <p>
                実際、"イノカ人"に共通する姿勢として「無理して地球の代弁者になるよりも『自然が大好きな私としてどうしたいか』を考える」主体性や、「激変の時代に更新され続ける価値観の中で『私たちなりの正解』を捉え続ける」柔軟性があります。エゴのように聞こえる人もいるかもしれません。それでも、主語を自分にするからこそ発揮できるパワーがあると、私たちは信じています。
              </p>
              <p>ぜひ意気込んで応募してください！お会いできる日を、心から楽しみにしています。</p>
            </Locale>
            <Locale lang="en">
              <p>
                In other words, it can be said that I have to have a sense of balance between my love for nature and my
                own feelings, which will ultimately benefit society and the earth. In other words, I have been able to
                face my own earnest desire, which is unquestionable.
              </p>
              <p>
                I want people who are willing to take initiative. If you come to work at innoqua, you would be able to
                focus on what matters most which is what you can do to protect nature and the earth that you care for
                deeply, you'd be able to take action against the increasing damage done to nature.
              </p>
              <p>Please apply! We sincerely look forward to meeting you.</p>
            </Locale>
          </RecruitContent>
          <RecruitContent
            css={css`
              padding-top: 10px;
              text-align: right;
            `}
          >
            <Locale lang="ja">
              <p>株式会社イノカCEO</p>
            </Locale>
            <Locale lang="en">
              <p>CEO of innoqua Co.</p>
            </Locale>
          </RecruitContent>
          <RecruitContentBig
            css={css`
              text-align: right;
            `}
          >
            <Locale lang="ja">
              <p>高倉葉太</p>
            </Locale>
            <Locale lang="en">
              <p>Yota Takakura</p>
            </Locale>
          </RecruitContentBig>
        </div>
        <div
          css={css`
            ${contentBaseCss};
          `}
        >
          <HeadingV1
            css={css`
              padding-top: 2.5rem;
              padding-left: 1.5rem;
            `}
          >
            Uniqueness
          </HeadingV1>
        </div>
        <Block>
          <img
            src="/images/aquatope.jpg"
            alt="Aquatope"
            css={css`
              width: 100%; // 圖片寬度佔據整個容器
              height: auto; // 保持寬高比
            `}
          />
          <Inner>
            <div
              css={css`
                ${contentBaseCss};
              `}
            >
              <BlockHeading>
                <Locale lang="ja">働く・探る・学ぶが一体となった独自施設</Locale>
                <Locale lang="en">A unique facility that combines working, exploring, and learning.</Locale>
              </BlockHeading>
              <BlockTitle>
                <Locale lang="ja">AQUATOPE 0.35</Locale>
                <Locale lang="en">AQUATOPE 0.35</Locale>
              </BlockTitle>
              <BlockContent>
                <Locale lang="ja">
                  イノカに入ると「AQUATOPE
                  0.35」というオフィス・研究所・コミュニティスペースが一体となった独自施設で働くことになります。特にコミュニティスペースでは、事業部の違いに関係なく誰もが自分の企画を立ち上げ、外部の方を招いた交流をすることができます。
                  <br />
                  教育イベントを開いてもよし、研究を前進させる知恵を募ってもよし。全ては、あなたのワクワクと地球課題解決の両立を実現するためです。
                  <br />
                  来る2027年には完全体「AQUATOPE 1.0」を立ち上げ予定。あなたの知恵と熱量を貸してください。
                </Locale>
                <Locale lang="en">
                  When you join innoqua, you will work in a unique facility called "AQUATOPE 0.35," which combines an
                  office, a research center, and a community space. In particular, the community space allows everyone,
                  regardless of division, to launch their own projects and invite outside parties to interact with them.
                  <br />
                  You can hold educational events or solicit wisdom to advance your research. All of this to achieve a
                  balance in pursuing your exciting workplace goals and solving global issues.In the coming year 2027,
                  we plan to launch the complete "AQUATOPE 1.0. Please lend us your wisdom and enthusiasm.
                </Locale>
              </BlockContent>
              <div
                css={css`
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  padding-top: rem;
                `}
              >
                <DetailLink href={'/news'} color={theme.baseColor.blue}>
                  LEARN MORE
                </DetailLink>
              </div>
            </div>
          </Inner>
        </Block>
        <Block>
          <img
            src="/images/team-s.jpg"
            alt="Team S"
            css={css`
              width: 100%; // 圖片寬度佔據整個容器
              height: auto; // 保持寬高比
            `}
          />
          <Inner>
            <div
              css={css`
                ${contentBaseCss};
                padding-bottom: 5rem;
              `}
            >
              <BlockHeading>
                <Locale lang="ja">異なる背景を前進する力に変える</Locale>
                <Locale lang="en">Transforming different backgrounds into the power to move forward</Locale>
              </BlockHeading>
              <BlockTitle>
                <Locale lang="ja">Team Diversity</Locale>
                <Locale lang="en">Team Diversity</Locale>
              </BlockTitle>
              <BlockContent>
                <Locale lang="ja">
                  イノカには「自然や生き物が好き」な気持ちで共通しつつも、背景や分野の異なるプロフェッショナルたちが揃っています。また、共に難しい時期を過ごしてきたからこそ、敬意を持ってお互いから学び、違う価値観を許容しながらも熱く議論を交わすことが身についています。そして、この姿勢は給与形態にも落とし込まれており、職種別に特殊な制度を作ることをしていません。全員がフラットな立場でスタートし、具体的なアクションによって評価される。そんな組織作りに取り組んでいます。
                </Locale>
                <Locale lang="en">
                  At innoqua, we are a team of professionals from different backgrounds and fields who share a common
                  love of nature (and other living organisms). As we have gone through difficult times together, we have
                  learned to respect each other, learned from each other and engaged in heated discussions while
                  tolerating different values. This attitude is also incorporated into our salary structure, we do not
                  have a special system for each type of job.
                </Locale>
              </BlockContent>
            </div>
          </Inner>
        </Block>
        <Section
          css={css`
            padding-top: 2.5rem;
            background: #ffffff;
          `}
          id="company"
          className={className}
        >
          <Inner>
            <HeadingV1>
              <Locale lang="ja">募集要項</Locale>
              <Locale lang="en">Recruitment information</Locale>
            </HeadingV1>
            <div
              css={css`
                table {
                  position: relative;
                  top: 30px;
                  ${theme.text.sm};
                  ${theme.fontWeight.medium};
                  border-collapse: collapse;
                  width: 100%;
                }
                tbody {
                }
                tr {
                  border-bottom: 1px solid ${theme.baseColor.black};
                }
                th {
                  white-space: nowrap;
                  text-align: left;
                  width: 20%;
                }
                td {
                  padding-left: 0.5625rem;
                }
                th,
                td {
                  padding-top: 0.5625rem;
                  padding-bottom: 0.5625rem;
                }
                @media (min-width: ${theme.breakpoint}) {
                  th {
                    width: 34%;
                  }
                }
              `}
            >
              <Locale lang="ja">
                <table>
                  <tbody>
                    <tr>
                      <th>募集職種</th>
                      <td>
                        <p>
                          研究員【とくに採用強化中！】
                          <br />
                          大阪立ち上げメンバー（次期拠点長候補）【とくに採用強化中！】
                          <br />
                          教育イベントスタッフ
                          <br />
                          生態圏エンジニア
                          <br />
                          バックオフィス【とくに採用強化中！】
                          <br />
                          エンジニア
                          <br />
                          ※適正や希望に応じて、職種を跨いで活躍していただきます。
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th>勤務地</th>
                      <td>
                        <p>
                          本社：東京都文京区
                          <br />
                          関西ラボ：大阪府吹田市
                          <br />
                          栃木ラボ：栃木県宇都宮市
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th>勤務時間</th>
                      <td>
                        <p>10:00〜19:00(内休憩1時間　実働8時間)</p>
                      </td>
                    </tr>
                    <tr>
                      <th>給与</th>
                      <td>
                        <p>
                          正社員 262,500円
                          <br />
                          （基本給 200,000円＋固定残業代 62,500円）
                          <br />
                          ※試用期間あり（〜6ヶ月）
                          <br />
                          241,780円
                          <br />
                          （基本給 184,210円＋固定残業代 57,570円）
                        </p>
                        <p>アルバイト 時給1113円～</p>
                      </td>
                    </tr>
                    <tr>
                      <th>福利厚生</th>
                      <td>
                        <p>
                          社会保険
                          <br />
                          厚生年金
                          <br />
                          労災保険
                          <br />
                          年に1回の健康診断
                          <br />
                          慶弔休暇
                          <br />
                          育児休業休暇
                          <br />
                          介護休暇
                          <br />
                          オフィス出社時は玄米無料（食べ放題！）
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th>休日休暇</th>
                      <td>
                        <p>GW・夏季・年末年始休暇あり</p>
                        <p>有給休暇あり</p>
                      </td>
                    </tr>
                    <tr>
                      <th>受動喫煙防止措置の状況</th>
                      <td>
                        <p>喫煙ルームあり</p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Locale>
              <Locale lang="en">
                <table>
                  <tbody>
                    <tr>
                      <th>Recruitment position</th>
                      <td>
                        <p>
                          Researcher ※Currently, we are in need of researchers and actively recruiting.
                          <br />
                          Sales staff
                          <br />
                          Educational event staff
                          <br />
                          Ecosphere Engineer
                          <br />
                          Back office
                          <br />
                          Engineer
                          <br />
                          ※Cross-cutting between job types, depending on suitability and desire.
                        </p>
                      </td>
                    </tr>
                    <tr>
                      <th>Work location</th>
                      <td>
                        <p>
                          Head office: Bunkyo-ku, Tokyo
                          <br />
                          Kansai Lab: Suita, Osaka
                          <br />
                          Tochigi Lab: Tochigi, Japan
                          <br />
                          Malaysia Lab:Cyberjaya, Malaysia(Branch planned for 2024)
                        </p>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </Locale>
            </div>
          </Inner>
        </Section>
        <div
          css={css`
            padding-top: 100px;
            padding-bottom: 100px;
            background-color: #ffffff;
          `}
        >
          <Locale lang="ja">
            <button
              onClick={() =>
                (window.location.href = 'https://docs.google.com/forms/d/1o1b53Rc0Mq-kdCDQix6iCWlxvyif2-OHcwoXEiyHJ6I')
              }
              css={css`
                background-color: #009bff;
                color: white;
                padding: 15px 30px;
                border-radius: 15px;
                cursor: pointer;
                position: relative;
                top: 90%;
                left: 50%;
                transform: translate(-50%, -50%);
                ${theme.text.lg};
                ${theme.fontWeight.medium};
              `}
            >
              ENTRY
            </button>
          </Locale>
          <Locale lang="en">
            <button
              onClick={() => (window.location.href = 'https://forms.gle/GvT7HcC4qRkRoYdj6')}
              css={css`
                background-color: #009bff;
                color: white;
                padding: 15px 30px;
                border-radius: 15px;
                cursor: pointer;
                position: relative;
                top: 90%;
                left: 50%;
                transform: translate(-50%, -50%);
                ${theme.text.lg};
                ${theme.fontWeight.medium};
              `}
            >
              ENTRY
            </button>
          </Locale>
        </div>
        <Inner>
          <HeadingV1
            css={css`
              padding-top: 2.5rem;
              padding-left: 1.5rem;
            `}
          >
            More Information
          </HeadingV1>
        </Inner>
        <Banner
          heading={<span>MISSION</span>}
          href="/about#mission-philosophy-value"
          sources={[
            {
              query: `(min-width: ${theme.breakpoint})`,
              src: '/images/about-1d.jpg',
            },
            {
              src: '/images/about-1s.jpg',
            },
          ]}
          className={darkBgClassName}
        >
          <Locale lang="ja">
            人類の選択肢を増やし、
            <br css={theme.atBreakpoint.hide} /> 人も自然も
            <br css={theme.atBreakpoint.hide} /> 栄える世界をつくる
          </Locale>
          <Locale lang="en">
            Increase Human options
            <br />
            and
            <br />
            Create a World
            <br />
            where
            <br />
            both people and nature
            <br />
            can be better
          </Locale>
        </Banner>
        <Banner
          css={css`
            margin-top: 15px;
          `}
          heading={
            <span>
              {' '}
              CORE
              <br css={theme.atBreakpoint.replaceWithSpace} /> TECHNOLOGY
            </span>
          }
          href="/about#core-technology"
          sources={[
            {
              query: `(min-width: ${theme.breakpoint})`,
              src: '/images/about-2d.jpg',
            },
            {
              src: '/images/about-2s.jpg',
            },
          ]}
          className={bannerClassName}
          contentCss={css`
            position: relative;
            top: -1.575rem;
            @media (min-width: ${theme.breakpoint}) {
              top: 0;
            }
          `}
        >
          <Locale lang="ja">環境移送技術&reg;</Locale>
          <Locale lang="en">
            Environmental
            <br />
            Transfer
            <br />
            Technology
          </Locale>
        </Banner>
        <div
          css={css`
            background-color: #edfdff;
            ${theme.padding.left};
            padding-top: 0;
            padding-right: 0;
            padding-bottom: 5rem;
            @media (min-width: ${theme.breakpoint}) {
              padding-left: 0;
            }
          `}
        >
          <div
            css={css`
              max-width: ${theme.breakpoint};
              margin: 0 auto;
            `}
          >
            <div>
              <HeadingV1
                css={css`
                  margin-top: 30px;
                  font-size: 22px;
                  font-weight: bold;
                `}
              >
                INNOQUA People
              </HeadingV1>
              <FeaturedNews items={featuredNews.news} />
              <DetailLink
                css={css`
                  float: right;
                `}
                href={'/news?category=JOIN+US'}
                color={theme.baseColor.blue}
              >
                VIEW ALL
              </DetailLink>
            </div>
            <div>
              <HeadingV1
                css={css`
                  margin-top: 30px;
                  font-size: 22px;
                  font-weight: bold;
                `}
              >
                Culture / History
              </HeadingV1>
              <FeaturedNews items={featuredNews.think} />
              <DetailLink
                css={css`
                  float: right;
                `}
                href={'/news?category=THINK'}
                color={theme.baseColor.blue}
              >
                VIEW ALL
              </DetailLink>
            </div>
          </div>
        </div>
        <Breadcrumbs
          items={[
            {
              name: 'TOP',
              href: '/',
            },
            {
              name: 'RECRUIT',
              href: '',
            },
          ]}
        />
        <ContactSectionV1 />
      </OuterV1>
    </>
  )
}
